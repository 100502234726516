import React, { Component } from "react";
import { Tooltip } from "antd";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";

class DisableWrapper extends Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.myRef = React.createRef();

    const coordinatorAvailable = Helpers.currentEnv().COORDINATOR_AVAILABLE === "true";
    const currentEnv = Helpers.currentEnvName();
    this.state = {
      coordinatorAvailable: coordinatorAvailable,
      currentEnv: currentEnv,
    };
  }

  componentDidMount() {
    const currentEnv = this.state.currentEnv;
    const tooltipMessage = this.context.hasWriteRole
      ? `Action not allowed in ${currentEnv} environment`
      : `User does not have write permissions`;

    this.setState({ tooltipMessage });
  }

  render() {
    const {
      children,
      message: overrideDisabledMessage,
      enabledMessage,
    } = this.props;

    const isAllowed =
      this.state.coordinatorAvailable && this.context.hasWriteRole;

    const tooltipMessage = overrideDisabledMessage || this.state.tooltipMessage;

    if (isAllowed) {
      // Render children normally if the environment is allowed
      return enabledMessage ? (
        <Tooltip title={enabledMessage} placement="top">
          {children}{" "}
        </Tooltip>
      ) : (
        <>{children}</>
      );
    }

    const wrappedChildren = React.Children.map(children, (child) => {
      if (React.isValidElement(child)) {
        return React.cloneElement(child, {
          disabled: true, // Force disabled
        });
      }
      return child;
    });

    return (
      <Tooltip title={tooltipMessage} placement="top" color="red">
        <>{wrappedChildren}</>
      </Tooltip>
    );
  }
}

export default DisableWrapper;
