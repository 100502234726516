import React, { Component } from "react";
import { Table, Alert } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import * as _ from "underscore";
import "../main.css";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";

class ImportResults extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();

    this.id = props.params.id;
    this.props = props;
    this.state = {
      loading: false,
      providerInstruments: [],
    };
  }

  componentDidMount = async () => {};

  render() {
    const { isSuccess, errorMessage } = this.props.uploadResponse;

    return (
      <div>
        {isSuccess ? (
          <CheckCircleOutlined style={{ fontSize: "64px", color: "#52c41a" }} />
        ) : (
          // Render an Alert if CsvInvalidReason is populated
          <Alert
            message="HTTP Error"
            description={errorMessage}
            type="error"
            showIcon
          />
        )}
      </div>
    );
  }
}

ImportResults.contextType = AppContext;
export default Helpers.withParams(ImportResults);
