import React, { Component } from "react";
import { Divider, Layout, Menu } from "antd";
import "../main.css";
import { AppContext } from "../dataContext";
import { Helpers } from "../helpers/helpers";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import EntityImporter from "./entityImporter";

const { Content } = Layout;

class EntityImportPage extends Component {
  static contextType = AppContext;
  formRef = React.createRef();

  constructor(props) {
    super();
    this.props = props;
    this.connectorCoordinatorProxy = new ConnectorsCoordinatorProxy();
    this.validateInstruments = this.connectorCoordinatorProxy.validateInstruments.bind(this.connectorCoordinatorProxy);
    this.importInstruments = this.connectorCoordinatorProxy.importInstruments.bind(this.connectorCoordinatorProxy);
    this.validateProviderInstruments = this.connectorCoordinatorProxy.validateProviderInstruments.bind(this.connectorCoordinatorProxy);
    this.importProviderInstruments = this.connectorCoordinatorProxy.importProviderInstruments.bind(this.connectorCoordinatorProxy);

    this.state = {
      selectedKey: "instruments", // Default selection
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.context.updateMenuSelection("importProviderInstruments");
    this.setState({ loading: false });
  };

  componentDidUpdate = async (props) => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  items = [
    {
      label: "Instruments",
      key: "instruments",
    },
    {
      label: "Provider Instruments",
      key: "providerInstruments",
    },
  ];

  handleMenuClick = (e) => {
    this.setState({ selectedKey: e.key });
  };

  render() {
    const { selectedKey } = this.state;

    // Determine the props based on the selected menu item
    const entityImporterProps = selectedKey === "instruments"
      ? {
          templateFile: "instrumentsImportTemplate.csv",
          validateMethod: this.validateInstruments,
          importMethod: this.importInstruments,
        }
      : {
          templateFile: "providerInstrumentsImportTemplate.csv",
          validateMethod: this.validateProviderInstruments,
          importMethod: this.importProviderInstruments,
        };

    return (
      <Content style={{ padding: "1px" }}>
        <Menu
          mode="horizontal"
          items={this.items}
          onClick={this.handleMenuClick}
          selectedKeys={[selectedKey]}
        ></Menu>
        <Divider></Divider>
        <EntityImporter {...entityImporterProps} />
      </Content>
    );
  }
}

EntityImportPage.contextType = AppContext;
export default Helpers.withParams(EntityImportPage);
