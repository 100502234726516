import React, { Component } from "react";
import {
  Layout,
  message,
  Button,
  Space,
  Spin,
  Upload,
  Row,
  Col,
  Flex,
  Steps,
} from "antd";
import { InboxOutlined, FileOutlined } from "@ant-design/icons";
import "../main.css";
import { AppContext } from "../dataContext";
import { Helpers } from "../helpers/helpers";
import ImportValidationResults from "./validationResults";
import ImportResults from "./importResults";

const { Content } = Layout;

class EntityImporter extends Component {
  static contextType = AppContext;
  formRef = React.createRef();

  constructor(props) {
    super();
    this.props = props;

    this.state = {
      loading: true,
      fileList: [],
      uploadingDisabled: true,
      currentStep: 0,
      importValidationResults: null,
      validationFailed: false,
      uploadResults: null,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    this.context.updateMenuSelection("importProviderInstruments");
    this.setState({ loading: false });
  };

  componentDidUpdate = async (props) => {
    this._isMounted = true;
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  validateFile = async () => {
    this.setState({ loading: true });
    let file = this.state.fileList[0];
    var result = await this.props.validateMethod(file);
    console.log(result)
    if (!result || result.isValid === false) {
      this.setState({
        loading: false,
        currentStepStatus: "error",
        validationFailed: true,
        importValidationResults: result,
      });
    } else if (result.isValid === true) {
      this.setState({
        loading: false,
        currentStep: 3,
        importValidationResults: result,
      });
    }
  };

  uploadFile = async () => {
    this.setState({ loading: true });
    let file = this.state.fileList[0];
    var response = await this.props.importMethod(file);
    console.log(response);
    if (response?.status === 200) {
      this.setState({
        loading: false,
        currentStep: 3,
        currentStepStatus: "success",
        uploadResults: {
          isSuccess: true,
          errorMessage: null,
        },
      });
    } else {
      this.setState({
        loading: false,
        currentStep: 3,
        currentStepStatus: "error",
        uploadResults: {
          isSuccess: false,
          errorMessage: response
            ? "No response"
            : `Received ${response.status} response.`,
        },
      });
    }
  };

  onFileRemove = (file) => {
    this.setState((state) => {
      return {
        fileList: [],
        currentStep: 0,
        currentStepStatus: "proccess",
        importValidationResults: null,
        validationFailed: false,
        uploadResults: null,
      };
    });
  };

  customRequest = async ({ onProgress, onError, onSuccess, file }) => {
    console.log("Custom request for file:", file);

    // Check if the file is a CSV
    const isCSV = file.type === "text/csv" && file.name.endsWith(".csv");

    // If it's not a CSV, trigger an error
    if (!isCSV) {
      onError(new Error(`${file.name.toUpperCase()} is not a CSV file`));
      onProgress({ percent: 0 });
      return;
    }
    file.status = "done";
    this.setState({ fileList: [file], currentStep: 1 });
    onProgress({ percent: 100 });
    onSuccess("Successful upload");
  };

  render() {
    const { loading, currentStep } = this.state;

    return (
      <Layout style={{ height: "100vh" }}>
        {" "}
        {/* Full page height */}
        <Space
          direction="vertical"
          size="large"
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            padding: "20px",
          }}
        >
          <Content
            style={{ flex: 1, display: "flex", flexDirection: "column" }}
          >
            <Spin spinning={loading}>
              {/* Steps at the top */}
              <Row justify="center" style={{ marginBottom: "20px" }}>
                <Steps
                  current={currentStep}
                  items={[
                    { title: "Upload CSV File" },
                    { title: "Validate Entities" },
                    { title: "Upload Entities" },
                  ]}
                  status={this.state.currentStepStatus}
                />
              </Row>

              {/* Main content in three columns */}
              <Flex align="center" justify="space-around">
                {/* Left Column: CSV Drag-and-Drop */}
                <Col
                  span={7}
                  style={{
                    justifyContent: "flex-start",
                    height: "100%",
                    overflow: "none",
                  }}
                >
                  <Upload.Dragger
                    maxCount={1}
                    fileList={this.state.fileList}
                    onChange={this.onChange}
                    onRemove={this.onFileRemove}
                    customRequest={this.customRequest}
                    onSuccess={() => message.success("File added successfully")}
                    onError={(error) => {
                      message.error(`File upload failed: ${error.message}`);
                      return { status: "error", percent: 0 };
                    }}
                  >
                    <p className="ant-upload-drag-icon">
                      <InboxOutlined />
                    </p>
                    <p className="ant-upload-text">
                      Click or drag a CSV file to upload entities
                    </p>
                    <p className="ant-upload-hint">
                      All the required columns are listed in the template files
                      above.
                    </p>
                  </Upload.Dragger>
                  <div style={{ marginTop: "16px", textAlign: "center" }}>
                    <a
                      href={this.props.templateFile}
                      style={{ fontSize: "14px" }}
                    >
                      <FileOutlined style={{ marginRight: "8px" }} />
                      Download Template
                    </a>
                  </div>
                </Col>

                {/* Middle Column: Validation Status */}
                <Col
                  span={7}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {this.state.importValidationResults ? (
                    <ImportValidationResults
                      importValidationResults={
                        this.state.importValidationResults
                      }
                    ></ImportValidationResults>
                  ) : (
                    <Button
                      type="primary"
                      disabled={
                        currentStep !== 1 &&
                        this.state.validationFailed !== true
                      }
                      style={{ width: "100%", marginBottom: "32px" }}
                      size="large"
                      shape="round"
                      onClick={this.validateFile}
                    >
                      Validate
                    </Button>
                  )}
                </Col>

                {/* Right Column: Upload Button */}
                <Col
                  span={7}
                  style={{
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  {this.state.uploadResults === null ? (
                    <Button
                      type="primary"
                      disabled={currentStep !== 3}
                      style={{ width: "100%", marginBottom: "32px" }}
                      size="large"
                      shape="round"
                      onClick={this.uploadFile}
                    >
                      {this.uploading ? "Uploading" : "Start Upload"}
                    </Button>
                  ) : (
                    <ImportResults
                      uploadResponse={this.state.uploadResults}
                    ></ImportResults>
                  )}
                </Col>
              </Flex>
            </Spin>
          </Content>
        </Space>
      </Layout>
    );
  }
}

EntityImporter.contextType = AppContext;
export default Helpers.withParams(EntityImporter);
