import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Layout, Table, Button, Modal, Typography, Space, Tooltip } from "antd";
import { SwapOutlined, PlusOutlined } from "@ant-design/icons";
import "../main.css";
import { RenderHelpers } from "../helpers/renderHelpers";
import { AppContext } from "../dataContext";
import { Helpers } from "../helpers/helpers";
import DynamicDataRepositoryDashProxy from "../proxies/dashProxy";
import ConnectorsCoordinatorProxy from "../proxies/connectorsCoordinatorProxy";
import DisableWrapper from "../components/disableWrapper";

const { Content } = Layout;
const { Title } = Typography;

class Dash extends Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.dynamicDataRepositoryDashProxy = new DynamicDataRepositoryDashProxy({
      dashSummary: this.dashSummary,
    });
    this.connectorsCoordinatorProxy = new ConnectorsCoordinatorProxy();

    const columns = [
      {
        width: 50,
        render: RenderHelpers.renderWarning,
      },

      {
        title: "Connector Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        render: (text, record) => {
          return (
            <NavLink to={"/providerInstruments/providers/" + record.name}>
              {text}
            </NavLink>
          );
        },
      },
      {
        title: "Active",
        dataIndex: "isAActive",
        key: "isAActive",
        render: RenderHelpers.renderAB,
      },
      {
        title: "A",
        dataIndex: "hostAStatus",
        key: "hostAStatus",
        render: RenderHelpers.renderFeedStatus,
      },
      {
        title: "B",
        dataIndex: "hostBStatus",
        key: "hostBStatus",
        render: RenderHelpers.renderFeedStatus,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (text, record) => {
          return (
            <NavLink to={"/providers/" + record.name + "/all"}>{text}</NavLink>
          );
        },
      },
      {
        title: "Active",
        dataIndex: "active",
        key: "active",
        render: (text, record) => {
          return (
            <NavLink to={"/providers/" + record.name + "/active"}>
              {text}
            </NavLink>
          );
        },
      },
      {
        title: "Failed",
        dataIndex: "failing",
        key: "failing",
        render: (text, record) => {
          return (
            <NavLink to={"/providers/" + record.name + "/failed"}>
              {text}
            </NavLink>
          );
        },
      },
      {
        width: 50,
        render: (text, record) => {
          return (
            <Space>
              <DisableWrapper enabledMessage={`Swap A and B`}>
                <Button
                  disabled={
                    record.hostAStatus !== "Running" ||
                    record.hostBStatus !== "Running"
                  }
                  type="primary"
                  shape="circle"
                  onClick={() => this.showModal(record.name)}
                  icon={<SwapOutlined />}
                />
              </DisableWrapper>
              <DisableWrapper
                enabledMessage={`Add a new ${record.name} instrument`}
              >
                <Button
                  type="primary"
                  shape="circle"
                  icon={<PlusOutlined />}
                  onClick={() => this.addNew(record)}
                ></Button>
              </DisableWrapper>
            </Space>
          );
        },
      },
    ];

    const passiveColumns = [
      {
        width: 50,
        render: RenderHelpers.renderWarning,
      },

      {
        title: "Connector Name",
        dataIndex: "name",
        key: "name",
        width: 150,
        render: (text, record) => {
          return text;
        },
      },
      {
        title: "Active",
        dataIndex: "isAActive",
        key: "isAActive",
        render: RenderHelpers.renderAB,
      },
      {
        title: "A",
        dataIndex: "hostAStatus",
        key: "hostAStatus",
        render: RenderHelpers.renderFeedStatus,
      },
      {
        title: "B",
        dataIndex: "hostBStatus",
        key: "hostBStatus",
        render: RenderHelpers.renderFeedStatus,
      },
      {
        title: "Total",
        dataIndex: "total",
        key: "total",
        render: (text, record) => {
          return (
            <NavLink to={"/providers/" + record.name + "/all?mt4=true"}>
              {text}
            </NavLink>
          );
        },
      },
    ];

    this.state = {
      columns: columns,
      passiveColumns: passiveColumns,
      treeData: [],
      markets: [],
      marketGroups: [],
      marketSuperGroups: [],
      marketQuotes: [],
      whiteLabels: [],
      loading: true,
      selectedPlatform: undefined,
      selectedRiskGroup: undefined,
      selectedStatus: undefined,
      selectedRegion: undefined,
      platformsLoading: true,
      statusesLoading: true,
      riskGroupsLoading: true,
      regionsLoading: true,
      filteredOutData: undefined,
      openPositionsLoading: true,
      isModalVisible: false,
      mt4Loading: true,
      mt4Providers: [],
    };
  }

  showModal = (name) => {
    this.setState({ selectedProvider: name });
    this.setState({ isModalVisible: true });
  };

  handleOk = async () => {
    this.setState({ confirmLoading: true });
    console.log(
      `Swapping connector instance from ${
        this.state.selectedProvider.IsAActive ? "A" : "B"
      } to ${this.state.selectedProvider.IsAActive ? "B" : "A"}`
    );
    await this.connectorsCoordinatorProxy.swapConnectorInstance(
      this.state.selectedProvider
    );
    this.setState({ isModalVisible: false });
    this.setState({ confirmLoading: false });
  };

  handleCancel = () => {
    this.setState({ isModalVisible: false });
  };

  componentDidMount = async () => {
    this._isMounted = true;
    this.setState({ loading: true });
    this.context.updateMenuSelection("dash");
    await this.dynamicDataRepositoryDashProxy.connect();
    await this.dynamicDataRepositoryDashProxy.subscribeAndOverride({});
  };

  componentWillUnmount = async () => {
    this._isMounted = false;
    await this.dynamicDataRepositoryDashProxy.stop();
  };

  addNew = (provider) => {
    this.context.showInstrumentAdd(provider);
  };

  dashSummary = (message) => {
    if (this._isMounted) {
      let activeProviders = message.filter((x) => !x.isPassive);
      let passiveProviders = message.filter((x) => x.isPassive);
      Helpers.sort(activeProviders, (x) => x.name);
      Helpers.sort(passiveProviders, (x) => x.name);
      this.setState({
        providers: activeProviders,
        passiveProviders: passiveProviders,
      });
      this.setState({ loading: false });
    }
  };

  render() {
    return (
      <AppContext.Consumer>
        {({ state }) => {
          return (
            <Layout className="layout">
              <Content style={{ padding: "0 5px" }}>
                <div className="site-layout-content">
                  <Title level={4}>Active</Title>
                  <Table
                    rowKey="id"
                    size="middle"
                    loading={this.state.loading}
                    columns={this.state.columns}
                    dataSource={this.state.providers}
                    onChange={this.handleChange}
                    pagination={false}
                  />
                  <Title level={4}>Passive</Title>
                  <Table
                    rowKey="id"
                    size="middle"
                    loading={this.state.loading}
                    columns={this.state.passiveColumns}
                    dataSource={this.state.passiveProviders}
                    onChange={this.handleChange}
                    pagination={false}
                  />
                </div>
              </Content>
              <Modal
                maskClosable={false}
                closable={false}
                title={this.state.selectedProvider}
                open={this.state.isModalVisible}
                onOk={this.handleOk}
                confirmLoading={this.state.confirmLoading}
                onCancel={this.handleCancel}
              >
                <p>Do you really want to swap A &amp; B connectors?</p>
              </Modal>
            </Layout>
          );
        }}
      </AppContext.Consumer>
    );
  }
}
Dash.contextType = AppContext;
export default Dash;
