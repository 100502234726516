import React, { Component } from "react";
import { CSVLink } from "react-csv";
import {
  Layout,
  Form,
  Button,
  Space,
  Spin,
  Divider,
  Tooltip,
} from "antd";
import "../../main.css";
import { Helpers } from "../../helpers/helpers";
import { AppContext } from "../../dataContext";
import PricingHubProxy from "../../proxies/pricinghubProxy";
import { InstrumentAddOrEditHelper } from "../helpers/instrumentAddOrEditHelper";

const { Content } = Layout;

class InstrumentExport extends Component {
  static contextType = AppContext;
  formRef = React.createRef();
  csvLinkExport = React.createRef();
  csvLinkFullExport = React.createRef();

  constructor(props) {
    super();
    this.pricingHubProxy = new PricingHubProxy();
    this.state = {
      loading: true,
      exportFailed: false,
      csvExportInstruments: [],
      csvFullExportInstruments: [],
      fullHeaders: [
        "name",
        "description",
        "primaryProvider",
        "primaryProviderInstrument",
        "primaryFairValue",
        "secondaryProvider",
        "secondaryProviderInstrument",
        "secondaryFairValue",
        "backwardationFix",
        "zeroPriceFilter",
        "maxChangeDiscard",
        "maxChangeMovementValue",
        "maxChangeNumberOfPrices",
        "timeoutFailover",
        "timeoutInSeconds",
        "timeoutInSecondsNight",
        "publishedSource",
        "mt4Name",
        "enabled",
      ],
    };
  }

  componentDidMount = async () => {
    this.prepareExport();
  };

  prepareExport = async () => {
    const {
      priceStatusValues,
      namesFromSearch: names,
      providerValue,
      tagValue: tags,
      operationValue: operation,
    } = this.props.currentInstrumentSearchAndFilter;

    let csvInstruments =
      await this.pricingHubProxy.exportInstrumentsSearch({
        providerName: providerValue,
        names: names,
        tags: tags && tags.length > 0 ? tags : [],
        priceStatus: priceStatusValues,
        strict: operation === "equals",
      });
    const convertedFullCsvInstruments = csvInstruments.map((x) =>
    InstrumentAddOrEditHelper.convertToImportDto(x)
    );
    if (convertedFullCsvInstruments) {
      this.setState({
        csvFullExportInstruments: convertedFullCsvInstruments,
        loading: false,
        exportFailed: false,
      });
    } else {
      this.setState({ exportFailed: true });
    }
  };

  confirm = async (e) => {
    this.csvLinkFullExport.link.click();
  };

  cancel = () => {
    this.formRef.current.resetFields();
    this.context.updateContext("isInstrumentExportVisible", false);
  };

  _prefixGenerator = () => {
    let prefix = "";
    prefix += this.state.showFailed ? "FAILED_" : "";
    prefix += this.state.search
      ? this.state.search.split(" ").join("_").toUpperCase() + "_"
      : "";
    prefix += this.state.providerValue ? this.state.providerValue + "_" : "";
    prefix += this.state.tagValue ? this.state.tagValue + "_" : "";
    return prefix;
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const onFinishFailed = (errorInfo) => {
      console.error("Failed:", errorInfo);
    };

    return (
      <Content style={{ padding: "0 5px" }}>
        <Spin spinning={this.state.loading}>
          <Form
            ref={this.formRef}
            {...layout}
            onFinish={this.confirm}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Space>
                <Button key="cancel" onClick={this.cancel}>
                  Cancel
                </Button>
                <Tooltip
                  title={
                    this.state.exportFailed ? `Failed to load instruments` : ""
                  }
                  color="red"
                >
                  <Button
                    key="primary"
                    type="primary"
                    htmlType="submit"
                    disabled={this.state.loading && !this.state.exportFailed}
                    loading={this.state.loading}
                  >
                    Confirm
                  </Button>
                </Tooltip>
              </Space>
            </Form.Item>
            <div>
              <CSVLink
                data={this.state.csvFullExportInstruments}
                headers={this.state.fullHeaders}
                filename={this._prefixGenerator() + "full.csv"}
                ref={(r) => (this.csvLinkFullExport = r)}
                target="_blank"
                className="hidden"
              />
            </div>
          </Form>
        </Spin>
      </Content>
    );
  }
}

InstrumentExport.contextType = AppContext;
export default Helpers.withParams(InstrumentExport);
