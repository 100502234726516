import React, { Component } from "react";
import { Form, Select, Divider, Input } from "antd";
import * as _ from "underscore";
import "../../../main.css";
import { FormContext } from "./formContext";
import { RenderHelpers } from "../../../helpers/renderHelpers";
import PricingHubProxy from "../../../proxies/pricinghubProxy";

const { Option } = Select;

class PrimaryFeedSettings extends Component {
  static contextType = FormContext;

  constructor(props) {
    super();
    this.props = props;

    this.throttledPrimarySearch = _.throttle(this.onPrimaryFinalSearch, 2000, {
      leading: false,
    });

    this.pricingHubProxy = new PricingHubProxy();
    this.state = {
      primaryProviderInstruments: [],
    };
  }

  onPrimarySearch = async (search) => {
    if (search.length > 2) {
      this.setState({ primarySearch: search, primarySearchLoading: true }, () =>
        this.throttledPrimarySearch()
      );
    }
  };

  onPrimaryFeedChanged = async (providerName) => {
    let primaries = [];
    if (this.context.isInstrumentAdd === false) {
      primaries = await this.pricingHubProxy.getProviderInstrumentsForProvider(
        providerName
      );
    }
    if (this.context.instrument.primaryFeed === providerName)
      primaries.push({
        name: this.context.instrument.primaryName,
        id: this.context.instrument.primaryId,
      });
    else {
      primaries = [];
      this.context.formRef.current.setFieldsValue({
        primaryName: undefined,
        primaryId: undefined,
      });
    }
    this.setState({
      primaryProviderInstruments: primaries,
    });
  };

  onPrimaryProviderInstrumentChanged = (value, option) => {
    if(this.state.primaryProviderInstruments)
    {
    let found = this.state.primaryProviderInstruments.filter(
      (x) => String(x.id) === option.key
    )[0];
    this.context.formRef.current.setFieldsValue({ primaryId: found?.id });
  }
  };

  onPrimaryFinalSearch = async () => {
    let instrument = this.context.formRef.current.getFieldsValue();
    let primaries = await this.pricingHubProxy.getProviderInstrumentsForProvider(
      instrument.primaryFeed,
      this.state.primarySearch
    );
    if (this.context.instrument.primaryFeed)
      primaries.push({
        name: this.context.instrument.primaryName,
        id: this.context.instrument.primaryId,
        enabled: this.context.instrument.primaryEnabled,
      });
    this.setState({
      primaryProviderInstruments: primaries,
      primarySearchLoading: false,
    });
  };

  render() {
    return (
      <div>
        <Form.Item name="primaryId" hidden="true"></Form.Item>
        <Form.Item name="primaryConnectorMapId" hidden="true"></Form.Item>
        <Form.Item name="primaryEnabled" hidden="true"></Form.Item>
        <Divider orientation="left">Primary Provider</Divider>
        <Form.Item
          label="Connector"
          name="primaryFeed"
          rules={[{ required: true, message: "Connector is mandatory!" }]}
        >
          <Select
            options={this.context.providers}
            onChange={this.onPrimaryFeedChanged}
          ></Select>
        </Form.Item>
        <Form.Item
          label="Symbol"
          name="primaryName"
          rules={[{ required: true, message: "Symbol is mandatory!" }]}
        >
          <Select
            showSearch
            allowClear="true"
            loading={this.state.primarySearchLoading}
            onSearch={this.onPrimarySearch}
            onChange={this.onPrimaryProviderInstrumentChanged}
          >
            {this.state.primaryProviderInstruments?.map((x) => (
              <Option
                key={x.id}
                value={x.name}
                label={
                  x.name + x.cmcId + x.bloombergId + x.isin + x.description
                }
              >
                <div>
                  <b>{x.name}</b>,
                  {RenderHelpers.renderProviderInstruments(x.cmcId, "cmcId")},
                  {RenderHelpers.renderProviderInstruments(
                    x.bloombergId,
                    "bloombergId"
                  )}
                  ,{RenderHelpers.renderProviderInstruments(x.isin, "isin")},{" "}
                  {x.description ? x.description : "-"}
                </div>
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Fair value" name="primaryFairValue">
          <Input type="number" defaultValue="0" />
        </Form.Item>
      </div>
    );
  }
}

PrimaryFeedSettings.contextType = FormContext;
export default PrimaryFeedSettings;
