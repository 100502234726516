import React from "react";
import { Space, Tooltip, Button, Col } from "antd";
import { PlusOutlined, ExportOutlined } from "@ant-design/icons";
import { AppContext } from "../dataContext";
import DisableWrapper from "../components/disableWrapper";

class InstrumentPageButtons extends React.Component {
  static contextType = AppContext;
  csvLinkExport = React.createRef();
  csvLinkFullExport = React.createRef();
  constructor(props) {
    super();
    this.props = props;
    this._isMounted = false;
    this.state = {};
  }

  addNew = () => {
    this.context.showInstrumentAdd();
  };

  showInstrumentExport = async () => {
    let instrumentNames = await this.props.getAllInstrumentNames();
    this.context.showInstrumentExport(
      this.props.currentSearchAndFilter,
      instrumentNames
    );
  };

  render() {
    return (
      <Col
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Space>
          <DisableWrapper enabledMessage={"Add a new instrument"}>
            <Button
              type="primary"
              shape="circle"
              icon={<PlusOutlined />}
              onClick={() => this.addNew()}
            ></Button>
          </DisableWrapper>
          <Tooltip title="Instrument export">
            <Button
              type="primary"
              shape="circle"
              icon={<ExportOutlined />}
              onClick={this.showInstrumentExport}
            ></Button>
          </Tooltip>
        </Space>
      </Col>
    );
  }
}

InstrumentPageButtons.contextType = AppContext;
export default InstrumentPageButtons;
