import axios from "axios";
import axiosRetry from "axios-retry";
import { Helpers } from "../helpers/helpers";
import { DataHelpers } from "../helpers/dataHelpers";
import * as _ from "underscore";
import { InstrumentPageDataHelper } from "../instrument/helpers/instrumentPageDataHelper";

class PricingHubProxy {
  constructor() {
    this.DATA_ACCESS_API_URL = //"http://localhost:7022/api/"
       Helpers.currentEnv().DYNAMIC_DATA_REPOSITORY_API_URL  
       + "pricinghub/";
    this.APPLICATION = "DASHBOARD";
    console.debug(
      "Dashboard DataAccess API: ",
      Helpers.currentEnvName(),
      this.DATA_ACCESS_API_URL
    );

    axiosRetry(axios, {
      retries: 3,
      shouldResetTimeout: true,
      retryCondition: (error) => {
        console.debug(error);
        return true;
      },
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }


  async providers() {
    var response;
    try {
      response = await axios.get(this.DATA_ACCESS_API_URL + "providers", {
        timeout: 10000,
        headers: {
          application: this.APPLICATION,
        },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug("Data repository Providers:", response);
    return response.data;
  }

  async getDisabledProviderInstruments(search, toBeDisabledCurrentFilterValues, connectedToInstrument) {
    let cancelled = false;
    let response;
    try {
      const queryParams = new URLSearchParams();
      if (search){ 
        queryParams.append("search", search);
      }
      if (toBeDisabledCurrentFilterValues && toBeDisabledCurrentFilterValues.length > 0){
        queryParams.append(
          "toBeDisabledCurrentFilterValues",
          toBeDisabledCurrentFilterValues
        );
      }
      if (connectedToInstrument){
        queryParams.append("connectedToInstrument", connectedToInstrument);
      }
  
      const url = `${this.DATA_ACCESS_API_URL}providerInstruments/disabled?${queryParams.toString()}`;

      if (this.cancelTokenSource) this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
  
      response = await axios.get(url, {
        cancelToken: this.cancelTokenSource.token,
        timeout: 30000,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
      cancelled = true;
    }

    const results =
      response && response.status === 200 ? response?.data : [];
    console.debug(
      "Connectors Coordinator Disabled Provider Instruments Search:",
      results
    );
    return { disabledProviderInstruments: results, cancelled };
  }

  async getDisabledProviderInstrument(providerInstrumentId) {
    var response;
    try {
      const baseURL =
        this.DATA_ACCESS_API_URL + `providerInstruments/disabled/${providerInstrumentId}`;
      console.log("DisabledProviderInstrument search: ", baseURL);
      if (this.cancelTokenSource) this.cancelTokenSource.cancel();
      this.cancelTokenSource = axios.CancelToken.source();
      response = await axios.get(baseURL, {
        cancelToken: this.cancelTokenSource.token,
        timeout: 30000,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    console.debug(response);
    var results = response && response.status === 200 ? response?.data : [];
    console.debug(
      "Connectors Coordinator Disabled Provider Instrument Search:",
      results
    );
    return { result: results };
  }

  async providerInstrumentsSearch(providerName, search, page = 1) {
    let url = `${this.DATA_ACCESS_API_URL}providerInstruments?pageSize=10&page=${page}`;
    if (providerName) {
        url = `${this.DATA_ACCESS_API_URL}providerInstruments/${providerName}?pageSize=10&page=${page}`;
    }
    if (search) {
        url += `&search=${search}`;
    }

    if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel();
    }

    this.cancelTokenSource = axios.CancelToken.source();

    try {
        const response = await axios.get(url, {
            cancelToken: this.cancelTokenSource.token,
            timeout: 30000,
            headers: { application: this.APPLICATION },
        });

        const results = response.status === 200 ? response.data : [];
        return {
            providerInstruments: results,
            counter: results.length,
            cancelled: false,
        };
    } catch (error) {
        console.error("Error in providerInstrumentsSearch:", error);
        return {
            providerInstruments: [],
            counter: 0,
            cancelled: true,
        };
    }
  }

  async getInstrumentsForProvider(providerName) {
    var response;
    try {
      response = await axios.get(
        this.DATA_ACCESS_API_URL +
          "connectorMaps/providers/" +
          providerName +
          "?includeAllRelevant=true",
        {
          timeout: 10000,
          headers: { application: this.APPLICATION },
        }
      );
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository ConnectorMaps:", providerName, results);
    return results;
  }

  async getAllInstruments() {
    var response;
    try {
      response = await axios.get(this.DATA_ACCESS_API_URL + "instruments/all", {
        timeout: 30000,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Data repository All ConnectorMaps:", results);
    return results;
  }

  async getInstrument(name) {
    var response;
    try {
      response = await axios({
        method: "get",
        url:
          this.DATA_ACCESS_API_URL + "instruments/" + encodeURIComponent(name),
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    console.debug("Pricing Hub Get Instrument by Name:", name, results);
    return results[0];
  }

  async getTagOptions(visible = null) {
    var response;
    try {
      response = await axios.get(
        `${this.DATA_ACCESS_API_URL}tags?enabled=true${
          visible !== null ? `& visible=${visible}` : ``
        }`,
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    return response.data;
  }

  async getPriceStatusOptions() {
    var response;
    try {
      response = await axios.get(
        this.DATA_ACCESS_API_URL + "pricestatus",
        { headers: { application: this.APPLICATION } }
      );
    } catch (error) {
      console.error(error);
    }
    var results = response.data;
    return results;
  }

  async instrumentsSearch(search, page) {
    var response;
    page = !page ? 1 : page;
    try {
      response = await axios({
        method: "post",
        url:
          this.DATA_ACCESS_API_URL +
          `instruments/search?page=${page}`,
        data: search,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var counter = response?.data.counter;
    var convertedConnectorMaps = [];
    for (var x of results) {
      convertedConnectorMaps.push(
        InstrumentPageDataHelper.convertResponseToInstrumentPageObject(x)
      );
    }
    var names = DataHelpers.extractInstrumentNamesFromConnectorMaps(
      convertedConnectorMaps
    );
    var payload = { connectorMaps: convertedConnectorMaps, counter, names };
    console.debug(
      "Dashboard data access Instruments search:",
      search,
      payload
    );
    return payload;
  }

  async instrumentSearch(search) {
    var response;
    try {
      response = await axios({
        method: "post",
        url:
          this.DATA_ACCESS_API_URL +
          `instruments/searchInstrumentNames`,
        data: search,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var counter = response?.data.counter;

    var payload = { instrumentNames: results, counter: counter };
    console.debug(
      "instrumentSearch. Url:",
      this.DATA_ACCESS_API_URL +
        `instruments/searchInstrumentNames`,
      "DashSearch: ",
      search,
      "Paylaod: ",
      payload
    );
    return payload;
  }

  async exportInstrumentsSearch(search) {
    var response;
    try {
      response = await axios({
        method: "post",
        url:
          this.DATA_ACCESS_API_URL +
          `instruments/search?noPaging=${true}`,
        data: search,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data.results : [];
    var convertedConnectorMaps = [];
    for (var x of results) {
      convertedConnectorMaps.push(
        InstrumentPageDataHelper.convertResponseToInstrumentPageObject(x)
      );
    }
    console.debug(
      "Dynamic data repository Export of ConnectorMaps search:",
      search,
      convertedConnectorMaps
    );
    return convertedConnectorMaps;
  }

  async identifyUsedProviderInstruments(ids) {
    var response;
    try {
      response = await axios({
        method: "get",
        url:
          this.DATA_ACCESS_API_URL +
          `providerInstruments/used`,
        data: ids,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data : [];
    console.debug(
      "Dynamic data repository Identify Used ProviderInstruments:",
      results
    );
    return results;
  }

  async getProviderInstrumentsForProvider(providerName, search) {
    var response;
    var queryString = search == null ? "" : `?search=${search}`
    const url = this.DATA_ACCESS_API_URL + `providerInstruments/${providerName}` + queryString
    try {
      response = await axios({
        method: "get",
        url: url,
        headers: { application: this.APPLICATION },
      });
    } catch (error) {
      console.debug(error);
    }
    var results =
      response && response.status === 200 ? response?.data : [];
    console.debug(
      "Dynamic data repository get provider instruments for provider:",
      url,
      results
    );
    return results;
  }

  async getPriceStatusesForInstruments(ids) {
    var response;
    try {
      response = await axios({
        method: "post",
        timeout: 30000,
        url: this.DATA_ACCESS_API_URL + "instruments/priceStatus",
        data: ids,
      });
    } catch (error) {
      console.error(error);
    }
    var results =
      response && response.status === 200 ? response?.data : [];
    console.debug(
      "Dashboard DataAccess PricesStatus search:",
      ids,
      results
    );
    return results;
  }
}

export default PricingHubProxy;
