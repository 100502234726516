import React, { Component } from "react";
import {
  Layout,
  Input,
  Form,
  Button,
  Checkbox,
  Select,
  Modal,
  Space,
  Spin,
  Tooltip,
} from "antd";
import { SwapOutlined } from "@ant-design/icons";
import * as _ from "underscore";
import "../../../main.css";
import { AppContext } from "../../../dataContext";
import { Helpers } from "../../../helpers/helpers";
import ConnectorsCoordinatorProxy from "../../../proxies/connectorsCoordinatorProxy";
import { InstrumentAddOrEditHelper } from "../../helpers/instrumentAddOrEditHelper";
import InstrumentSettings from "./instrumentSettings";
import PrimaryFeedSettings from "./primaryFeedSettings";
import SecondaryFeedSettings from "./secondaryFeedSettings";
import { FormContext } from "./formContext";
import PricingHubProxy from "../../../proxies/pricinghubProxy";

const { Content } = Layout;

class InstrumentAddOrEdit extends Component {
  static contextType = AppContext;
  formRef = React.createRef();

  constructor(props) {
    super();

    const coordinatorAvailable =
      Helpers.currentEnv().COORDINATOR_AVAILABLE === "true";
    this.props = props;
    this.pricingHubProxy = new PricingHubProxy();
    this.connectorsCoordinatorProxy = new ConnectorsCoordinatorProxy();

    this.instrumentSettingsRef = React.createRef();
    this.primaryFeedSettingsRef = React.createRef();
    this.secondaryFeedSettingsRef = React.createRef();

    this.state = {
      loading: true,
      search: undefined,
      instrument: props,
      coordinatorAvailable: coordinatorAvailable,
      secondaryFeedPopulated: false, // instrument add only
      isInstrumentAdd: props.isInstrumentAdd,
    };
  }

  componentDidMount = async () => {
    this._isMounted = true;
    await this.extract();
  };

  componentWillUnmount = () => {
    this._isMounted = false;
  };

  extract = async () => {
    this.setState({ loading: true });

    console.log(
      `Extracting connector map. Existing connector map (for cloning): `, this.props.instrumentToClone, "Connector map :", this.props.instrumentToEdit
    );
    let { provider, instrumentToClone, instrumentToEdit } = this.props;
    if (this.props.isInstrumentAdd) {
      instrumentToEdit = InstrumentAddOrEditHelper.createNewInstrument(
        provider?.name,
        instrumentToClone
      );
    }
    let providers = await this.getProviders();
    this.instrumentSettingsRef.current.setInitialValues(instrumentToEdit);
    this.secondaryFeedSettingsRef.current.setInitialValues(instrumentToEdit);

    this.setState({
      instrument: instrumentToEdit,
      provider,
      providers,
      loading: false,
    });
    console.log(`Extracting connector map: ${instrumentToEdit}`);
    this.formRef.current.setFieldsValue(instrumentToEdit);
  };

  getProviders = async () => {
    const providers = await this.pricingHubProxy.providers();
    return providers.map((x) => ({
      key: x.name,
      label: x.name,
      value: x.name,
    }));
  };

  updateTimeout = (value) => {
    this.formRef.current.setFieldsValue({ timeoutFailover: value });
    this.instrumentSettingsRef.current.updateTimeout(value);
  };

  updateSecondaryFeedPopulated = (value) => {
    this.setState({ secondaryFeedPopulated: value });
  };

  swapPrimaryAndSecondary = () => {
    let instrument = this.formRef.current.getFieldsValue();
    if (instrument.secondaryFeed && instrument.secondaryEnabled) {
      instrument = InstrumentAddOrEditHelper.swapPrimaryAndSecondary(instrument);
      this.formRef.current.setFieldsValue(instrument);
    }
  };

  cancelInstrumentAdd = () => {
    this.formRef.current.resetFields();
    this.context.updateContext("isInstrumentAddOrEditVisible", false);
  };

  saveInstrument = async (e) => {
    let errors = this.formRef.current.getFieldsError();
    if (_.every(errors, (x) => x["errors"].length === 0)) {
      this.setState({ saveLoading: true });

      let instrumentFrom = this.formRef.current.getFieldsValue();
      instrumentFrom.timeoutFailover = instrumentFrom.secondaryFeed ? true : false;
      let convertedInstrument = InstrumentAddOrEditHelper.convertToImportDto(instrumentFrom);

      if (this.props.isInstrumentAdd) {
        await this.connectorsCoordinatorProxy.addInstrument(convertedInstrument);
      }
      else {
        await this.connectorsCoordinatorProxy.updateInstrument(instrumentFrom.id, convertedInstrument);
      }

      this.setState({ saveLoading: false });
      this.context.updateContext("isInstrumentAddOrEditVisible", false);
    }
  };

  onConfigTypeChanged = (value, option) => {
    this.formRef.current.setFieldsValue({ configType: value });
  };

  showConfirmationModal = (name) => {
    if (!this.state.isInstrumentAdd) {
      this.setState({ deleteLoading: true });
    }
    this.setState({ selectedProvider: name });
    this.setState({ isModalVisible: true });
  };

  onDeleteOk = async () => {
    this.setState({ confirmLoading: true });
    await this.connectorsCoordinatorProxy.deleteInstrument(this.state.instrument.id);
    this.setState({ isModalVisible: false });
    this.setState({ confirmLoading: false });
    this.setState({ deleteLoading: false });
    this.context.updateContext("isInstrumentAddOrEditVisible", false);
  };

  onDeleteCancel = () => {
    this.setState({ isModalVisible: false });
    this.setState({ deleteLoading: false });
  };

  updateContext = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {
    const layout = {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
    };

    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    const onFinishFailed = (errorInfo) => {
      console.error("Failed:", errorInfo);
    };

    const onValuesChange = (changedValues, allValues) => {
      console.debug(changedValues);
      console.debug(allValues);
    };

    return (
      <FormContext.Provider
        value={{
          state: this.state,
          updateContext: this.updateContext,
          instrument: this.state.instrument,
          formRef: this.formRef,
          isInstrumentAdd: this.state.isInstrumentAdd,
          providers: this.state.providers,
        }}
      >
        <Content style={{ padding: "0 5px" }}>
          <Spin spinning={this.state.loading}>
            <Form
              ref={this.formRef}
              {...layout}
              onFinish={this.saveInstrument}
              onFinishFailed={onFinishFailed}
              onValuesChange={onValuesChange}
            >
              {!this.state.coordinatorAvailable && (
                <Form.Item {...tailLayout}>
                  <h2 className="editingDisabledWarning">{`Cannot add instruments in ${Helpers.currentEnvName().toLowerCase()} environment.`}</h2>
                </Form.Item>
              )}
              <Form.Item label="Id" name="id" hidden="true"></Form.Item>

              <Form.Item
                label="Name"
                name="publishedName"
                rules={[
                  { required: true, message: "Name is mandatory!" },
                  {
                    validator: async (_, value) => {
                      if (value.indexOf(" ") >= 0) {
                        return Promise.reject(
                          new Error("Name cannot contain spaces!")
                        );
                      }
                      return Promise.resolve();
                    },
                  },
                  {
                    validator: async (_, value) => {
                      // If instrument add, check if value.
                      // If not instrument add, check if value and value !== this.state.instrument.publishedName
                      if (
                        value &&
                        (this.state.isInstrumentAdd ||
                          (!this.state.isInstrumentAdd &&
                            value !== this.state.instrument.publishedName))
                      ) {
                        let result = await this.pricingHubProxy.getInstrument(value);
                        if (result) {
                          return Promise.reject(
                            new Error("Name needs to be unique!")
                          );
                        }
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item label="Description" name="description">
                <Input />
              </Form.Item>
              <Form.Item
                label="Published Source"
                name="publishedSource"
                rules={[
                  { required: true, message: "Published source is mandatory!" },
                ]}
              >
                <Select options={this.state.providers}></Select>
              </Form.Item>
              <Form.Item {...tailLayout} name="enabled" valuePropName="checked">
                <Checkbox>Enabled</Checkbox>
              </Form.Item>
              <InstrumentSettings
                ref={this.instrumentSettingsRef}
                secondaryFeedPopulated={this.state.secondaryFeedPopulated}
              />
              <PrimaryFeedSettings
                ref={this.primaryFeedSettingsRef}
              ></PrimaryFeedSettings>
              {this.state.isInstrumentAdd ? (
                <> </>
              ) : (
                <Form.Item label="Swap Primary & Secondary">
                  <Button
                    type="primary"
                    onClick={() => this.swapPrimaryAndSecondary()}
                    icon={<SwapOutlined />}
                  ></Button>
                </Form.Item>
              )}
              <SecondaryFeedSettings
                ref={this.secondaryFeedSettingsRef}
                updateTimeout={this.updateTimeout}
                updateSecondaryFeedPopulated={this.updateSecondaryFeedPopulated}
              ></SecondaryFeedSettings>
              <Form.Item {...tailLayout}>
                <Space>
                  {this.state.isInstrumentAdd ? (
                    <> </>
                  ) : (
                    <Tooltip
                      title={
                        !this.state.coordinatorAvailable
                          ? `Action prevented in ${Helpers.currentEnvName().toLowerCase()} environment`
                          : ""
                      }
                      color="red"
                    >
                      <Button
                        key="delete"
                        danger
                        disabled={
                          this.state.saveLoading ||
                          !this.state.coordinatorAvailable
                        }
                        onClick={this.showConfirmationModal}
                        loading={this.state.deleteLoading}
                      >
                        Delete
                      </Button>
                    </Tooltip>
                  )}
                  <Button
                    key="cancel"
                    disabled={this.state.saveLoading}
                    onClick={this.cancelInstrumentAdd}
                  >
                    Cancel
                  </Button>
                  <Tooltip
                    title={
                      !this.state.coordinatorAvailable
                        ? `Action prevented in ${Helpers.currentEnvName().toLowerCase()} environment`
                        : ""
                    }
                    color="red"
                  >
                    <Button
                      key="save"
                      type="primary"
                      htmlType="submit"
                      disabled={!this.state.coordinatorAvailable}
                      loading={this.state.saveLoading}
                    >
                      Save
                    </Button>
                  </Tooltip>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
          <Modal
            maskClosable={false}
            closable={false}
            title="Confirmation"
            open={this.state.isModalVisible}
            onOk={this.onDeleteOk}
            confirmLoading={this.state.confirmLoading}
            onCancel={this.onDeleteCancel}
          >
            <p>Do you really want to delete the selected instrument?</p>
          </Modal>
        </Content>
      </FormContext.Provider>
    );
  }
}

InstrumentAddOrEdit.contextType = AppContext;
export default InstrumentAddOrEdit;
