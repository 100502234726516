import React from "react";
import { Space, Tooltip, Button } from "antd";
import {
  CopyOutlined,
  EditOutlined,
  AreaChartOutlined,
} from "@ant-design/icons";
import { AppContext } from "../../dataContext";
import DisableWrapper from "../../components/disableWrapper";

class InstrumentTableButtons extends React.Component {
  static contextType = AppContext;
  constructor(props) {
    super();
    this.props = props;
    this._isMounted = false;
  }

  render() {
    const { record } = this.props;
    const { showInstrumentEdit, showInstrumentAdd } = this.context;
  
    const renderButton = (enabledMessage, icon, onClick) => (
      <DisableWrapper enabledMessage={enabledMessage}>
        <Button
          type="primary"
          shape="circle"
          icon={icon}
          disabled={record.editingDisabled}
          onClick={onClick}
        />
      </DisableWrapper>
    );
  
    const renderMarketMonitorButton = (isDisabled) => (
      <Tooltip title="Market Monitor">
        <a
          href={`#/marketMonitor/${encodeURIComponent(record.publishedName)}`}
          style={{ pointerEvents: isDisabled ? "none" : "auto" }}
        >
          <Button
            type="primary"
            shape="circle"
            icon={<AreaChartOutlined />}
            disabled={isDisabled}
          />
        </a>
      </Tooltip>
    );
  
    const marketIsPresent = record.marketIsPresent;
  
    return (
      <Space>
        {renderButton("Edit", <EditOutlined />, () => showInstrumentEdit(record))}
        {renderButton("Clone", <CopyOutlined />,() => showInstrumentAdd(null, record))}
        {renderMarketMonitorButton(!marketIsPresent)}
      </Space>
    );
  }
}

InstrumentTableButtons.contextType = AppContext;
export default InstrumentTableButtons;
