import React, { Component } from "react";
import { Table, Alert } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import * as _ from "underscore";
import "../main.css";
import { Helpers } from "../helpers/helpers";
import { AppContext } from "../dataContext";

class ImportValidationResults extends Component {
  static contextType = AppContext;

  constructor(props) {
    super();

    this.id = props.params.id;
    this.props = props;
    this.state = {
      loading: false,
      providerInstruments: [],
    };
  }

  componentDidMount = async () => {};

  render() {
    const { isValid, csvInvalidReason, failedValidationResults } =
      this.props.importValidationResults;

    const columns = [
      {
        title: "Row Number",
        dataIndex: "index",
        key: "index",
      },
      {
        title: "Reason",
        dataIndex: "reason",
        key: "reason",
      },
    ];

    return (
      <div>
        {isValid ? (
            <CheckCircleOutlined style={{ fontSize: '64px', color: "#52c41a"  }}/>
        ) : csvInvalidReason ? (
          // Render an Alert if CsvInvalidReason is populated
          <Alert
            message="CSV Invalid"
            description={csvInvalidReason}
            type="error"
            showIcon
          />
        ) : (
          // Render a Table if FailedValidationResults has data
          <Table
            dataSource={failedValidationResults?.map((item, index) => ({
              key: index,
              ...item,
            }))}
            columns={columns}
            bordered
          />
        )}
      </div>
    );
  }
}

ImportValidationResults.contextType = AppContext;
export default Helpers.withParams(ImportValidationResults);
