import React, { Component } from "react";
import { Input, Form, Checkbox, Select, Divider, Typography } from "antd";
import * as _ from "underscore";
import "../../../main.css";
import PricingHubProxy from "../../../proxies/pricinghubProxy";
import { FormContext } from "./formContext";

const { Option } = Select;

class InstrumentSettings extends Component {
  static contextType = FormContext;

  constructor(props) {
    super();
    this.props = props;

    this.pricingHubProxy = new PricingHubProxy();

    this.state = {
      currentTimeoutFailover: false,
      desiredTimeoutFailover: false,
      tags: [],
    };
  }

  componentDidMount = async () => {
    let tags = await this.pricingHubProxy.getTagOptions(true);
    this.setState({ tags: tags });
  };

  setInitialValues(connectorMap) {
    this.setState({
      desiredTimeoutFailover: !!connectorMap.secondaryFeed,
      currentTimeoutFailover: !!connectorMap.timeoutFailover,
    });
  }

  updateTimeout = (value) => {
    const timeout =
      value === true ||
      (value && value.target && value.target.checked === true);

    this.setState({
      currentTimeoutFailover: timeout,
      desiredTimeoutFailover: timeout,
    });
  };

  render() {
    const tailLayout = {
      wrapperCol: { offset: 8, span: 16 },
    };

    return (
      <div>
        <Divider />
        <Form.Item label="MT4 Name" name="mt4Name">
          <Input />
        </Form.Item>
        <Divider />
        <Form.Item
          {...tailLayout}
          name="backwardationFix"
          valuePropName="checked"
        >
          <Checkbox>Backwardation Removal</Checkbox>
        </Form.Item>
        <Form.Item
          {...tailLayout}
          name="zeroPriceFilter"
          valuePropName="checked"
        >
          <Checkbox>Zero Price Removal</Checkbox>
        </Form.Item>
        <Form.Item
          {...tailLayout}
          name="timeoutFailover"
          valuePropName="checked"
          rules={[
            {
              validator: async (_, value) => {
                if (
                  this.props.secondaryFeedPopulated !==
                  this.state.currentTimeoutFailover
                ) {
                  return Promise.reject(new Error(""));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Checkbox
            disabled={
              this.state.currentTimeoutFailover ===
              this.state.desiredTimeoutFailover
            }
            onChange={this.updateTimeout}
          >
            Instrument Timeout Failover
          </Checkbox>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Typography.Text
            type={
              this.props.secondaryFeedPopulated !==
              this.state.currentTimeoutFailover
                ? "danger"
                : "secondary"
            }
            strong
          >
            {this.props.secondaryFeedPopulated
              ? this.state.currentTimeoutFailover
                ? "Instrument timeout failover activated."
                : "Instrument timeout failover must be activated when using a secondary provider."
              : this.state.currentTimeoutFailover
              ? "Instrument timeout failover cannot be activated without a secondary provider."
              : "Set secondary provider to activate instrument timeout failover."}
          </Typography.Text>
        </Form.Item>
        <Form.Item
          label="Day Timeout"
          name="timeoutInSec"
          validateFirst={true}
          rules={[
            {
              validator: async (_, value) => {
                if (this.props.secondaryFeedPopulated && value <= 0) {
                  return Promise.reject(
                    new Error(
                      "Timeout value must be greater than 0 when using a secondary provider!"
                    )
                  );
                }
                return Promise.resolve();
              },
            },
            {
              required: true,
              message: "Timeout value is required!",
            },
          ]}
        >
          <Input type="number" min="0" addonAfter="s" />
        </Form.Item>
        <Form.Item
          label="Night Timeout"
          name="timeoutInSecAtNight"
          validateFirst={true}
          rules={[
            {
              validator: async (_, value) => {
                if (this.props.secondaryFeedPopulated && value <= 0) {
                  return Promise.reject(
                    new Error(
                      "Timeout value must be greater than 0 when using a secondary provider!"
                    )
                  );
                }
                return Promise.resolve();
              },
            },
            {
              required: true,
              message: "Timeout value is required!",
            },
          ]}
        >
          <Input type="number" min="0" addonAfter="s" />
        </Form.Item>
        <Divider orientation="left">Maximum Change</Divider>
        <Form.Item
          label="Maximum percentage allowed"
          name="maxChangeMovementValue"
        >
          <Input type="number" addonAfter="%" />
        </Form.Item>
        <Form.Item label="across number of" name="maxChangeNumberOfPrices">
          <Input type="number" addonAfter="prices" />
        </Form.Item>
        <Form.Item
          label="Discard price or set state to Indicative"
          name="maxChangeDiscard"
        >
          <Select>
            <Option value={false}>Indicative</Option>
            <Option value={true}>Discard</Option>
          </Select>
        </Form.Item>
      </div>
    );
  }
}

InstrumentSettings.contextType = FormContext;
export default InstrumentSettings;
